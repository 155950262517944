import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { clearUserData } from 'store/auth/auth.slice';
import { routes } from 'routes/routes';

export const useLogout = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   const logout = useCallback(() => {
      // Clear local storage and session storage
      localStorage.clear();
      sessionStorage.clear();

      // Clear user data in Redux store
      dispatch(clearUserData());

      // Navigate to the welcome route
      navigate(routes().welcome);
   }, [dispatch, navigate]);

   return { logout };
};
