import React, { useEffect } from 'react';
import { Box, useMediaQuery, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'wrappers';
import Logo2 from 'components/Logo2';

export const MobileRedirectPage = () => {
   const screenSE = useMediaQuery('(max-height:700px)');
   const location = useLocation();
   const { t } = useTranslation();
   document.title = `Mobile Redirect | ${t('siteTitleName')}`;

   useEffect(() => {
      goToApp();
   }, [location.pathname]);

   const goToApp = () => {
      const searchParams = new URLSearchParams(location.search);
      const redirectDeepLink = searchParams.get('url');
      if (redirectDeepLink != null) {
         const appUrl = decodeURI(redirectDeepLink);
         if (appUrl) {
            console.log('Redirecting to app:', appUrl);
            window.location.replace(`so.bono.app://${appUrl}`);
         }
      }
   };

   return (
      <PageWrapper topPadding={false} logo={false} logoSpace={!screenSE}>
         <Box
            component='section'
            display='flex'
            flexDirection='column'
            width='100%'
            paddingBottom='50%'
            mt='110px'
            justifyContent='center'
         >
            <Box
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               alignItems='center'
               textAlign='center'
            >
               <Box component='div' mb={3.75}>
                  <Logo2 />
               </Box>
               <Box>
                  <Typography fontSize={20} fontWeight={700}>
                     {t('mobileRedirect.title')}
                  </Typography>
               </Box>
               <Box>
                  <Typography color='#999999' fontWeight={600} fontSize={16}>
                     {t('mobileRedirect.subtitle')}
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box sx={{ width: '100%', maxWidth: '350px', mb: '74px', mt: 'auto' }}>
            <Button onClick={goToApp} variant='primaryBtn'>
               <Typography>{t('mobileRedirect.buttonText')}</Typography>
            </Button>
         </Box>
      </PageWrapper>
   );
};
