import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { PageWrapper } from 'wrappers';
import { routes } from 'routes/routes';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
   authState,
   fetchUserByAccessToken,
   fetchVerificationCode,
   getStripeFromUserProfile,
} from 'store/auth/auth.slice';
import { ContentWrapper, PageSubLabel, CustomMenuButton, BottomProfileBox } from 'components/common';

import { BasicModal } from 'modals/basic.modal';
import { useLogout } from 'hooks/useLogout';
import type { MenuItemTypes } from './type';

export const ProfilePage = () => {
   const { logout } = useLogout();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { loggedUserData } = useAppSelector(authState);
   const [dialog, setDialog] = useState(false);
   const [dialogPassword, setDialogPassword] = useState(false);
   const { t } = useTranslation();
   const [subscriptionLoader, setSubscriptionLoader] = useState(false);

   const openUrlInNewTab = (url: string) => {
      setTimeout(() => {
         window.open(url, '_blank');
      });
   };

   const navigateToStripe = () => {
      setSubscriptionLoader(true);
      dispatch(getStripeFromUserProfile())
         .then((response) => {
            if (response.payload?.code === 'ERR_BAD_REQUEST') {
               if (response.payload?.response.data.message === 'customer was not found by given criteria') {
                  navigate(routes().subscription);
               } else {
                  // setup popup to the user about error happend
                  setDialog(true);
               }
            }
            if (response.payload?.sessionUrl) {
               openUrlInNewTab(response.payload?.sessionUrl);
            }
         })
         .catch((err) => {
            console.log(err);
            setDialog(true);
         })
         .finally(() => {
            setSubscriptionLoader(false);
         });
   };

   const goToPortfolio = () => {
      sessionStorage.setItem('profileWasActivated', 'true');
      navigate(routes().portfolio);
   };

   const goToVerCodePage = () => {
      setDialogPassword(false);
      dispatch(fetchVerificationCode()).then(({ payload }) => {
         if (payload.response?.status === 404) {
            navigate(routes().myProfile);
            return;
         }
         sessionStorage.setItem('profileWasActivated', 'true');
         navigate(routes().resetPassword);
      });
   };

   const menus = [
      { id: 1, label: `${t('myProfile.menuItem1')}`, onclick: () => goToPortfolio(), visibility: true },
      {
         id: 8,
         label: `${t('myProfile.menuItem2')}`,
         onclick: () => navigate(routes().gratitude),
         visibility: true,
      },
      { id: 2, label: `${t('myProfile.menuItem3')}`, onclick: () => navigateToStripe(), visibility: true },
      {
         id: 5,
         label: `${t('myProfile.menuItem6')}`,
         url: `${process.env.REACT_APP_MAIN_BASE_URL}/tc/`,
         visibility: true,
      },
      {
         id: 6,
         label: `${t('myProfile.menuItem7')}`,
         url: `${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`,
         visibility: true,
      },
      { id: 7, label: `${t('myProfile.menuItem8')}`, onclick: () => logout(), visibility: true },
   ];

   if (t('myProfile.menuItem5') !== '') {
      menus.push({
         id: 4,
         label: `${t('myProfile.menuItem5')}`,
         url: 'https://intercom.help/the-bono-way/en',
         visibility: true,
      });
   }

   // don't allow user to go back to password page
   window.onpopstate = () => {
      const currentLocation = window.location.href;
      if (currentLocation.includes('profile') || currentLocation.toLocaleLowerCase().includes('password')) {
         navigate(routes().myProfile);
      }
   };

   useEffect(() => {
      if (!loggedUserData) {
         dispatch(fetchUserByAccessToken());
      }
   }, [dispatch, loggedUserData]);

   const displayName = () =>
      loggedUserData?.settings.registeredWith === 'google'
         ? `${loggedUserData.firstName} ${loggedUserData.lastName}`
         : ' ';

   const displayMail = () =>
      loggedUserData?.settings.registeredWith === 'google' ? `${loggedUserData.email} ` : 'null';

   const ToDisplay = () => loggedUserData?.settings.registeredWith === 'google';

   const handleMenuItemClick = (menu: MenuItemTypes) => {
      if (menu.url) {
         window.open(menu.url, '_blank');
      } else if (menu.onclick) {
         menu.onclick();
      }
   };

   useEffect(() => {
      document.title = `Profile | ${t('siteTitleName')}`;
   }, []);

   return (
      <PageWrapper>
         {!loggedUserData ? (
            <PageSubLabel>Loading....</PageSubLabel>
         ) : (
            <ContentWrapper>
               <Box component='div' width='100%'>
                  <Box
                     component='div'
                     display='flex'
                     flexDirection='column'
                     mb={2.5}
                     sx={{ wordBreak: 'break-word', textAlign: { xs: 'start', sm: 'center' } }}
                  >
                     <Typography className='ProfileNaming'>
                        Hi <span>{`${displayName()}`}</span>
                     </Typography>
                     <Typography component='p' className='ThanksAnImpact'>
                        {t('myProfile.subHeading')}
                     </Typography>
                  </Box>
                  <Box
                     component='div'
                     display='flex'
                     flexDirection='column'
                     gap={1.25}
                     marginX='auto'
                     sx={{ maxWidth: { sm: '346px' } }}
                  >
                     {menus
                        // .filter((menu) => menu.visibility)
                        .map((menu) => (
                           <CustomMenuButton
                              key={menu.id}
                              disabled={menu.label === `${t('myProfile.menuItem3')}` && subscriptionLoader}
                              onClick={() => handleMenuItemClick(menu)}
                           >
                              {menu.label}{' '}
                              <Box component='span' display='flex' alignItems='center' gap={1}>
                                 {menu.label === `${t('myProfile.menuItem3')}` && subscriptionLoader && (
                                    <CircularProgress size={24} color='black' />
                                 )}
                                 <Icon name={ENUM_ICON.ARROW_RIGHT_MENU} />{' '}
                              </Box>
                           </CustomMenuButton>
                        ))}
                     {!ToDisplay() && (
                        <BottomProfileBox>
                           <Typography component='p' className='ThanksAnImpact'>
                              You are signed in as:
                              <Box component='br' />
                              <Box component='span'>{`${loggedUserData.email}`}</Box>
                           </Typography>
                        </BottomProfileBox>
                     )}
                  </Box>
               </Box>
               <BasicModal
                  open={dialog}
                  onClose={() => setDialog(false)}
                  onNext={() => setDialog(false)}
                  title='Error logging subscription'
                  description='we are having a problem loading your subscription, please try again'
                  buttonLabel='ok'
               />
               <BasicModal
                  open={dialogPassword}
                  onClose={() => setDialogPassword(false)}
                  onNext={() => goToVerCodePage()}
                  title={t('myProfile.menuItem4')}
                  description={t('myProfile.changePasswordPopupText')}
                  buttonLabel={t('myProfile.changePasswordPopupCTA')}
               />
            </ContentWrapper>
         )}
      </PageWrapper>
   );
};
