import { Box, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { CharityTypes } from 'store/causes/types';

interface IImageCarouselProps {
   amount: number;
   charities: CharityTypes[];
}

const ImpactCarousel: React.FC<IImageCarouselProps> = ({ amount, charities }) => {
   const isBigScreen = useMediaQuery('(min-width:1040px)');
   const getCalculatedAmount = (indicatorPrice: number) =>
      `${Math.round((amount * 12) / indicatorPrice)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

   return (
      <Box
         sx={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: isBigScreen ? 'center' : 'start',
            overflowX: 'scroll',
            paddingBottom: '10px',
         }}
      >
         <ImageList
            cols={3}
            gap={10}
            sx={{
               width: 1040,
               height: '170px',
               display: 'flex',
               flexWrap: 'nowrap',
               scrollbarWidth: 'none', // For Firefox
               '&::-webkit-scrollbar': { display: 'none' }, // For Webkit
            }}
         >
            {charities.map((item) => (
               <ImageListItem
                  sx={{
                     minWidth: 340,
                     height: '170px',
                     borderRadius: '6px',
                     overflow: 'hidden',
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundImage: `url(${item.indicatorImage})`,
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'space-between',
                     color: 'white',
                  }}
                  key={item.id}
               >
                  <Box
                     sx={{
                        marginLeft: '10px',
                        marginTop: '10px',
                        borderRadius: '2px',
                        backgroundColor: '#EFCAC9',
                        py: '2px',
                        px: '8px',
                        width: 'fit-content',
                     }}
                  >
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           fontSize: '12px',
                           lineHeight: '18px',
                           fontWeight: 700,
                           textShadow: 'none',
                           color: '#333333',
                        }}
                     >
                        {item.causes[0]?.title}
                     </Typography>
                  </Box>

                  <Box
                     sx={{
                        px: '10px',
                        paddingBottom: '10px',
                        height: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
                     }}
                  >
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           color: '#ffffff',
                           fontSize: '24px',
                           fontWeight: 700,
                           lineHeight: '29px',
                        }}
                     >
                        {getCalculatedAmount(item.indicatorPrice)}
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           fontWeight: 700,
                           color: '#ffffff',
                           fontSize: '18px',
                           lineHeight: '22px',
                        }}
                     >
                        {item.indicatorTitle}
                     </Typography>
                  </Box>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );
};

export default ImpactCarousel;
