import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';

import { RegLinkText } from 'components/common';
import { authState } from 'store/auth/auth.slice';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import WelcomePageCells from 'components/welcomeCells/WelcomePageCells';
import LoginWithGoogle from 'components/loginButtons/LoginWithGoogle';
import LoginWithEmail from 'components/loginButtons/LoginWithEmail';
import { useLogout } from 'hooks/useLogout';
import { ButtonsBox, PageMainLabel, PageSubLabel } from './styles';

export const WelcomePageV2 = () => {
   const { logout } = useLogout();
   const navigate = useNavigate();
   const { t } = useTranslation();
   const [searchParams] = useSearchParams();
   const { loggedUserData } = useAppSelector(authState);
   const [returnUser, setReturnUser] = useState(searchParams.get('back') === 'true');

   const handleSwitchUser = () => {
      setReturnUser(false);
      logout();
   };

   useEffect(() => {
      document.title = t('siteTitleName');
   }, []);

   useEffect(() => {
      if (loggedUserData) {
         if (loggedUserData.subscription?.status === 'active') {
            navigate(routes().myProfile);
         } else {
            setReturnUser(true);
         }
      }
   }, [loggedUserData]);

   const handleNext = () => {
      if (loggedUserData) {
         navigate(`${routes().causes}${returnUser ? '?back=true' : ''}`);
      } else {
         navigate(routes().loginEmail);
      }
   };

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'space-between', sm: 'start' },
            pb: 4,
         }}
      >
         <Box
            component='div'
            display='flex'
            flexDirection='column'
            sx={{
               width: {
                  xs: '100%',
                  sm: '100%',
                  textAlign: 'left',
               },
            }}
         >
            <Box
               width='100%'
               sx={{
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: { xs: '30px', sm: '0px' },
               }}
            >
               <PageMainLabel
                  maxWidth={MAIN_CONTAINER_WIDTH}
                  sx={{ paddingBottom: '10px', px: { xs: 2.5, sm: 0 }, width: '100%' }}
               >
                  {returnUser && loggedUserData
                     ? `${t('homePage.headingBack')} ${loggedUserData.firstName}`
                     : t('homePage.heading')}
               </PageMainLabel>
               <PageSubLabel
                  maxWidth={MAIN_CONTAINER_WIDTH}
                  sx={{ paddingBottom: ['25px', '30px', '30px'], color: '#666666', px: { xs: 2.5, sm: 0 } }}
               >
                  {returnUser && loggedUserData
                     ? `${t('homePage.subHeadingBack')} ${loggedUserData.email}`
                     : t('homePage.subHeading')}
               </PageSubLabel>
               <WelcomePageCells />
            </Box>
         </Box>
         <Box width='100%' sx={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonsBox sx={{ px: { xs: 2.5, sm: 0 } }} maxWidth={MAIN_CONTAINER_WIDTH} pt='30px'>
               {returnUser ? (
                  <Button onClick={() => navigate(routes().causes)} variant='primaryBtn'>
                     <Typography>Next</Typography>
                  </Button>
               ) : (
                  <>
                     <LoginWithGoogle />
                     <LoginWithEmail handleOnClick={handleNext} />
                  </>
               )}
               {returnUser ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           fontSize: '16px',
                           lineHeight: '19px',
                           color: '#666666',
                        }}
                     >
                        {t('homePage.footerTextBack')}
                     </Typography>
                     <Button onClick={handleSwitchUser} sx={{ ':hover': { backgroundColor: 'transparent' } }}>
                        <Typography
                           sx={{
                              fontFamily: 'Figtree',
                              fontSize: '16px',
                              lineHeight: '19px',
                              color: '#666666',
                              textDecoration: 'underline',
                              textTransform: 'none',
                           }}
                        >
                           Switch account
                        </Typography>
                     </Button>
                  </Box>
               ) : (
                  <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
                     {t('clickTC')} <br />
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('privacyPolicy')}</span>
                     </Link>
                     <div>
                        <Trans
                           i18nKey='tcSupport'
                           // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                           components={{ mailLink: <a href='mailto:support@proisrael.co'></a> }}
                        />
                     </div>
                  </RegLinkText>
               )}
            </ButtonsBox>
         </Box>
      </Box>
   );
};
