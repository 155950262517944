import { Box } from '@mui/material';

import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BackIconButton } from 'components/common';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { commonState } from 'store/common/common.slice';
import { useAppSelector } from 'store/hooks';
import Logo2 from 'components/Logo2';
import { NAV_BG_COLOR } from 'styles/constants';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { routes } from '../../routes/routes';

export const Header = (): React.ReactElement => {
   const isMobile = useMediaQuery('(max-width:600px)');
   const navigate = useNavigate();
   const location = useLocation();
   const { project } = useAppSelector(commonState);
   const showBackButton = !(location.pathname === routes().portfolio && project === 'helene');

   const hideBackButton = ['/share', '/'];

   const handleBack = () => {
      if (location.pathname === routes().portfolio) {
         navigate(routes().causes);
      } else {
         navigate(-1);
      }
   };

   return (
      <Box
         component='nav'
         width='100%'
         minHeight={{ xs: '130px', sm: '60px' }}
         display='flex'
         sx={{
            position: { sm: 'fixed' },
            px: { sm: 3.75 },
            background: { xs: 'none', sm: NAV_BG_COLOR },
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 500,
         }}
      >
         {showBackButton ? (
            <BackIconButton
               sx={{
                  position: 'fixed',
                  display: {
                     xs: hideBackButton.includes(location.pathname) ? 'none' : 'flex',
                     sm: 'none',
                  },
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: { xs: '30px', sm: '90px' },
                  left: { xs: '20px', sm: '40px' },
               }}
               onClick={handleBack}
            >
               <DynamicGoogleIcon iconColor='#999999' iconName='arrow_back' />
            </BackIconButton>
         ) : (
            ''
         )}
         {(location.pathname === '/' && isMobile) || !isMobile ? (
            <Box
               sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'start' },
                  mt: { xs: '50px', sm: '0' },
               }}
            >
               <Logo2 />
            </Box>
         ) : null}
      </Box>
   );
};

export const PublicLayout = (): React.ReactElement => {
   const location = useLocation();
   return (
      <Box
         sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            // px: { xs: 2.5, sm: 0 },
            height: '100dvh',
            backgroundColor: '#F7F7F7',
         }}
         component='main'
      >
         {location.pathname !== routes().mobileRedirect && <Header />}
         <Box
            sx={{
               pt: { xs: 0, sm: '130px' },
               position: 'relative',
               display: 'flex',
               flex: 1,
               alignSelf: 'center',
               width: '100%',
               // overflow: { xs: 'auto', sm: 'visible' },
               '&::-webkit-scrollbar': { display: 'none' },
            }}
         >
            <Outlet />
         </Box>
      </Box>
   );
};

export const NoLayout = (): React.ReactElement => {
   return <Outlet />;
};
